import { io } from 'socket.io-client';
import configApi from '@/api/config';
import store from '@/store/store';
import { v4 as uuidv4 } from 'uuid';

let $socket = null;
let listeners = [];

function logListeners(eventName) {
  console.log('All listeners: ', listeners);
  console.log(
    `Listeners by ${eventName}`,
    listeners.filter((l) => l.event === eventName),
    $socket.listeners(eventName),
  );
}

export function connectSocket() {
  if (!store.getters['auth/isUserLoggedIn']) {
    return null;
  }

  if ($socket) {
    return $socket;
  }

  $socket = io(configApi.url, {
    query: {
      tenant: store.getters['auth/tenantId'],
      user: store.getters['auth/userId'],
    },
  });

  console.log('socket connected');

  return $socket;
}

export function getSocket() {
  return connectSocket();
}

export function registerSocketEvent(
  eventName,
  callback,
  listenerID = uuidv4(),
  avoidRepeated = false,
) {
  connectSocket();
  let result = false;
  const listenerIndex = listeners.findIndex((listener) => listener.id === listenerID);

  if ($socket && (listenerIndex === -1 || !avoidRepeated)) {
    const cb = (payload) => {
      console.log(`Listen ${eventName} with payload`, payload);
      callback(payload);
    };

    $socket.on(eventName, cb);
    listeners.push({
      event: eventName,
      id: listenerID,
      cb,
    });
    console.log(`socket event '${eventName}' with id '${listenerID}' registered`);
    result = true;
  } else {
    console.log(`socket event '${eventName}' with id '${listenerID}' not registered`);
  }

  logListeners(eventName);
  return result;
}

export function unregisterSocketEvent(
  eventName,
  listenerID,
) {
  let result = false;
  const listenerIndex = listeners.findIndex((listener) => listener.id === listenerID);

  if ($socket && listenerIndex !== -1) {
    $socket.off(eventName, listeners[listenerIndex].cb);
    listeners.splice(listenerIndex, 1);
    console.log(`socket event '${eventName}' with id '${listenerID}' unregistered`);
    result = true;
  } else {
    console.log(`socket event '${eventName}' with id '${listenerID}' not unregistered`);
  }

  logListeners(eventName);
  return result;
}

export function removeSocket() {
  if ($socket) {
    $socket.removeAllListeners();
    listeners = [];
    $socket.close();
    $socket = null;
  }
}

store.subscribe((mutation, { auth }) => {
  switch (mutation.type) {
    case 'auth/SET_AUTH_USER_IS_LOGGED_IN':
      if (auth.isUserLoggedIn) {
        connectSocket();
      } else {
        removeSocket();
      }
      break;

    case 'auth/DELETE_AUTH':
      removeSocket();
      break;
    default:
  }
});
